<template>
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="account-info-sec" v-if="translationloaded==true">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div id="show-hidden-menu" class="dashBoarsBtnMain" @click="toggledashboard()">
            <i class="fa fa-th-large" aria-hidden="true"></i>{{translate('dashboard')}}
          </div>
          <div id ="vdasboard"  class="hidden-menu" style="display: none;">
           <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
          <div class="mainSideDiplay">
            <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="account-details-sec">
            <h3>{{translate('address')}}</h3>
            <div class="row">
              <div class="col-md-12">
                <div class="row ">
                  <div class="col-md-12 ">
                    <div class="my_address_page">
                    <CustomerAddressList
                      :addressList="fetchCustomerAddressList"
                      @deleteCustomerAddress="deleteAddress"
                      @editAddress="editCustomerAddress"
                      @selectedCustId="selectedCustAddressId"
                       :translationloaded="translationloaded"
                      :translate="translate"
                    ></CustomerAddressList>
                    </div>
                  </div>

                    <div class="col-md-12">
                      <div class="col-md-4 pl-0">
                        <div class="addnew-address pl-0 mt-4" @click="addNewAddress">
                          + {{translate('addNewAddress')}}
                        </div>
                      </div>
                    </div>
                </div>

              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   <!--Address form section start-->
    <AddressForm
      :openStatus="address_from_open_status"
      @hideAddressForm="hideForm"
      :updateAddress="edit_customer_address"
      :isAddAddresClick="add_address_btn_click"
       :translationloaded="translationloaded"
      :translate="translate"
    ></AddressForm>
    <!--Address form section end-->
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import SideBar from "../components/my_account/SideBar";
import AddressForm from "../components/checkout/AddressForm";
import CustomerAddressList from "../components/checkout/CustomerAddressList";
import { mapGetters, mapActions } from "vuex";
import helper from "@/helper/helper";
import WhatsAppButton from "@/components/WhatsAppButton";

import $ from "jquery";
export default {
  name: "MyAddress",
  components: {
    Breadcrumb,
    SideBar,
    AddressForm,
    CustomerAddressList,
    WhatsAppButton
  },
  props:[
     'translationloaded',
     'translate'
  ],
  data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },

        {
          path: "/my-address",
          name: "myAddress",
        },
      ],
      address_from_open_status: false,
      edit_customer_address: "",
      add_address_btn_click: "",
      selected_cust_shipping_address: "",
      selected_cust_billing_address: "",
    };
  },
  computed: {
    ...mapGetters(["fetchCustomerAddressList"]),
  },
  mounted() {
    this.getCustomerAddressList();
    helper.backtoTop();
    
  },
  methods: {
     toggledashboard()
    {
       var x = document.getElementById("vdasboard");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
    ...mapActions(["getCustomerAddressList", "deleteCustomerAddress"]),
    addNewAddress() {
      this.address_from_open_status = true;
      this.add_address_btn_click = true;
       this.openAddressFormPopup();
    },
    hideForm() {
      this.address_from_open_status = false;
       $('#address_form_popup').removeClass('show');
        $('#address_form_popup').css({'display':'none'});
        $('.ps-site-overlay').css({'visibility':'hidden','opacity':' 0'});
    },
    deleteAddress(addressId) {
      let $this = this;
      $this
        .deleteCustomerAddress(addressId)
        .then(function(response) {
          $this.$toast.success(`${response.data.Message}`);
        })
        .catch(function(error) {
          $this.$toast.error(`${error.response.data.Message}`);
        });
    },
    editCustomerAddress(address) {
      this.edit_customer_address = address;
      this.address_from_open_status = true;
      this.add_address_btn_click = false;
       this.openAddressFormPopup();
    },
    selectedCustAddressId(addressId) {
      this.selected_cust_shipping_address = addressId;
      this.selected_cust_billing_address = addressId;
    },
      openAddressFormPopup(){

            $('#address_form_popup').addClass('show');
            $('#address_form_popup').css({'display':'block'});
            $('.ps-site-overlay').css({'visibility':'visible','opacity':' .8'});
    }
  },
};
</script>
<style scoped>
  .account-details-sec h3{
    margin-bottom:0px
  }
  .modal{
        top: 0 !important;
  }

  
</style>
